import React, { useCallback, useState, useEffect, useMemo } from 'react';
import styles from './calendar.module.scss';
import ReactCalendar from 'react-calendar';
import Dropdown from 'react-dropdown';
import moment from 'moment';
import { CustomPriceModel } from '../../../ui-models/unit-type.ui-model';
import NumberFormat from 'react-number-format';
const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);
/**
 * CalendarProps interface
 */
export interface CalendarProps {
  eventHandlers: {
    onChange: (date: Date | Date[]) => void;
  };
  data?: {
    calenderView?: boolean;
    disbaleDate?: Date[];
    addInitValue?: boolean;
    showDateInput?: boolean;
    basePriceTag?: number;
    customPriceTags?: CustomPriceModel[];
    selectedDate?: Date[] | Array<[Date, Date]>;
    multipleRanges?: boolean; // New prop for multiple range selection
    dateRanges?: Array<[Date, Date]>; // New prop for existing date ranges
  };
}

/**
 * functional component Calendar
 * @param data holding component data
 */
export const Calendar: React.FC<CalendarProps> = ({ eventHandlers, data }) => {
  const monthOptions = [
    { value: '0', label: 'Jan' },
    { value: '1', label: 'Feb' },
    { value: '2', label: 'Mar' },
    { value: '3', label: 'Apr' },
    { value: '4', label: 'May' },
    { value: '5', label: 'June' },
    { value: '6', label: 'July' },
    { value: '7', label: 'Aug' },
    { value: '8', label: 'Sep' },
    { value: '9', label: 'Oct' },
    { value: '10', label: 'Nov' },
    { value: '11', label: 'Dec' }
  ];
  const yearOptions = (() => {
    const max = new Date().getFullYear() + 9;
    const min = max - 9;
    const years: string[] = [];

    for (let i = max; i >= min; i--) {
      years.push(i.toString());
    }
    return years.reverse();
  })();
  const [value, onChange] = useState(data?.addInitValue ? tomorrow : null);
  const [monthValue, setMonthValue] = useState(monthOptions[tomorrow.getMonth()]);
  const [yearValue, setYearValue] = useState(tomorrow.getFullYear().toFixed());
  const [activeStartDate, setActiveStartDate] = useState(tomorrow);
  const [isActive, setIsActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date[] | Array<[Date, Date]>>([]);
  const [dateRanges, setDateRanges] = useState<Array<[Date, Date]>>(data?.dateRanges || []);

  const isDateInRanges = useMemo(
    () => (date: Date) => {
      if (!dateRanges || !Array.isArray(dateRanges)) {
        return false;
      }

      return dateRanges.some((range) => {
        // Check that range is valid array with two elements
        if (Array.isArray(range) && range.length === 2) {
          const [start, end] = range;
          return date >= start && date <= end;
        }
        return false;
      });
    },
    [dateRanges]
  );

  const handleDateClick = useCallback(
    (clickedDate: Date) => {
      if (data?.multipleRanges) {
        // Find if clicked date is within any existing range
        const rangeIndex = dateRanges.findIndex((range) => {
          // Ensure range is a valid array with two date elements
          if (Array.isArray(range) && range.length === 2) {
            const [start, end] = range;
            return (
              start instanceof Date &&
              end instanceof Date &&
              clickedDate >= start &&
              clickedDate <= end
            );
          }
          return false;
        });

        if (rangeIndex !== -1) {
          // Remove the range that was clicked
          const newRanges = [...dateRanges];
          newRanges.splice(rangeIndex, 1);
          setDateRanges(newRanges);
          eventHandlers.onChange(newRanges.flat());
        }
      }
    },
    [dateRanges, data?.multipleRanges, eventHandlers]
  );

  function onYearOrMonthChange(month?: any, year?: any) {
    month
      ? setActiveStartDate(new Date(Number(year ? year : yearValue), Number(month.value), 1))
      : setActiveStartDate(new Date(Number(year), Number(monthValue.value), 1));
  }

  useEffect(() => {
    if (Array.isArray(data?.selectedDate) && data.selectedDate.length > 0) {
      setSelectedDate(data.selectedDate);
    } else if (!data?.multipleRanges) {
      // Only set empty array when not in multipleRanges mode
      setSelectedDate([]);
    }
  }, [data]);

  return (
    <div>
      {data && data.calenderView ? (
        <div className={styles['calendar']}>
          <div
            className={`${styles['calendar__calendar-container']} ${styles['calendar__calendar-container--calender-view']}`}
            style={{ position: 'relative', width: '100%' }}
          >
            <div className={styles['calendar__calendar-container__dropdowns-container']}>
              <div className={styles['calendar__calendar-container__dropdowns-container__item']}>
                <Dropdown
                  options={monthOptions}
                  value={monthValue}
                  onChange={(val: any) => {
                    setMonthValue(val);
                    onYearOrMonthChange(val);
                  }}
                  placeholder="Select an option"
                />
              </div>
              <div className={styles['calendar__calendar-container__dropdowns-container__item']}>
                <Dropdown
                  options={yearOptions}
                  value={yearValue}
                  onChange={(val: any) => {
                    setYearValue(val.value);
                    onYearOrMonthChange(null, val.value);
                  }}
                  placeholder="Select an option"
                />
              </div>
            </div>
            <ReactCalendar
              tileDisabled={(date: any) => {
                if (data && Array.isArray(data.disbaleDate)) {
                  return data.disbaleDate
                    .map((date) => {
                      return new Date(date.toDateString()).toString();
                    })
                    .includes(new Date(date.date.toDateString()).toString());
                }
                return false;
              }}
              {...(data?.multipleRanges && {
                value: value
              })}
              onClickDay={(date) => handleDateClick(date)}
              onChange={(val: any) => {
                if (data?.multipleRanges) {
                  if (Array.isArray(val) && val.length === 2) {
                    // Check if new range overlaps with any existing range
                    const hasOverlap = dateRanges.some((range) => {
                      if (Array.isArray(range) && range.length === 2) {
                        const existingStart = range[0];
                        const existingEnd = range[1];
                        return val[0] <= existingEnd && val[1] >= existingStart;
                      }
                      return false;
                    });

                    if (!hasOverlap) {
                      const newRanges: Array<[Date, Date]> = [...dateRanges, [val[0], val[1]]];
                      setDateRanges(newRanges);
                      eventHandlers.onChange(newRanges.flat());
                    }
                  }
                } else {
                  if (
                    Array.isArray(val) &&
                    val.length === 2 &&
                    val[0].toDateString().toString() === val[1].toDateString().toString()
                  ) {
                    onChange(null as any);
                    eventHandlers.onChange(null as any);
                  } else {
                    onChange(val);
                    eventHandlers.onChange(val);
                  }
                }
              }}
              selectRange={true}
              next2Label={null}
              prev2Label={null}
              navigationLabel={() => null}
              allowPartialRange={true}
              tileContent={({ activeStartDate, date, view }) => {
                if (
                  view === 'month' &&
                  data &&
                  data.customPriceTags &&
                  data.customPriceTags.length > 0
                ) {
                  const matchedCustomPrice = data.customPriceTags.find(
                    (customPrice) =>
                      date <= new Date(moment(customPrice.to).format('MM-DD-YYYY')) &&
                      date >= new Date(moment(customPrice.from).format('MM-DD-YYYY'))
                  );
                  if (matchedCustomPrice) {
                    return (
                      <p
                        className={`${styles['calendar__calendar-container__price-tag']} ${styles['calendar__calendar-container__price-tag--custom-price']}`}
                      >
                        <NumberFormat
                          value={matchedCustomPrice.price}
                          thousandSeparator={true}
                          displayType={'text'}
                          prefix={'EGP '}
                        />
                      </p>
                    );
                  } else {
                    if (data.basePriceTag) {
                      return (
                        <p className={styles['calendar__calendar-container__price-tag']}>
                          <NumberFormat
                            value={data.basePriceTag}
                            thousandSeparator={true}
                            displayType={'text'}
                            prefix={'EGP '}
                          />
                        </p>
                      );
                    }
                  }
                } else {
                  if (data.basePriceTag) {
                    return (
                      <p className={styles['calendar__calendar-container__price-tag']}>
                        <NumberFormat
                          value={data.basePriceTag}
                          thousandSeparator={true}
                          displayType={'text'}
                          prefix={'EGP '}
                        />
                      </p>
                    );
                  }
                }
                if (data?.multipleRanges && isDateInRanges(date)) {
                  return <div className={styles['calendar__calendar-container__range-selected']} />;
                }
                return null;
              }}
              activeStartDate={activeStartDate}
              tileClassName={({ date }) => {
                const reservationDate =
                  Array.isArray(data.disbaleDate) &&
                  data.disbaleDate.find((disabledDate) => {
                    return (
                      date.toDateString().toString() === disabledDate.toDateString().toString()
                    );
                  });
                if (reservationDate) {
                  return styles['calendar__booked'];
                }
                return '';
              }}
              minDate={
                // tslint:disable-next-line: prettier
                ((Array.isArray(value) && value.length === 1) || value) && data?.disbaleDate?.length
                  ? (() => {
                      if (data && Array.isArray(data.disbaleDate)) {
                        const sortDisableDate = data.disbaleDate.sort(
                          (a, b) => b.getTime() - a.getTime()
                        );
                        const firstBiggerDate = sortDisableDate.find(
                          (sortedDate) =>
                            sortedDate.getTime() <
                            (Array.isArray(value) ? value[0]?.getTime() : value?.getTime())
                        );
                        return firstBiggerDate;
                      }
                    })()
                  : undefined
              }
              maxDate={
                // tslint:disable-next-line: prettier
                ((Array.isArray(value) && value.length === 1) || value) && data?.disbaleDate?.length
                  ? (() => {
                      if (data && Array.isArray(data.disbaleDate)) {
                        const sortDisableDate = data.disbaleDate.sort(
                          (a, b) => a.getTime() - b.getTime()
                        );
                        const firstBiggerDate = sortDisableDate.find(
                          (sortedDate) =>
                            sortedDate.getTime() >
                            (Array.isArray(value) ? value[0]?.getTime() : value?.getTime())
                        );
                        return firstBiggerDate;
                      }
                    })()
                  : undefined
              }
              onActiveStartDateChange={({ activeStartDate }) => {
                setYearValue(String(activeStartDate.getFullYear()));
                const findMonth = monthOptions.find((opt) => {
                  return opt.value === String(activeStartDate.getMonth());
                });
                if (findMonth) {
                  setMonthValue(findMonth);
                  onYearOrMonthChange(findMonth, String(activeStartDate.getFullYear()));
                }
              }}
            />
            {dateRanges?.length > 0 && (
              <div>
                {dateRanges?.map((range, index) => {
                  return (
                    <div
                      key={index}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <div
                        style={{
                          borderRadius: '8px',
                          border: '1px solid black',
                          padding: '5px',
                          margin: '5px',
                          fontSize: '14px'
                        }}
                      >
                        {range[0]?.toDateString()}
                      </div>
                      <div style={{ margin: '5px' }}>
                        <span style={{ fontSize: '20px' }}>→</span>
                      </div>
                      <div
                        style={{
                          borderRadius: '8px',
                          border: '1px solid black',
                          padding: '5px',
                          margin: '5px',
                          fontSize: '14px'
                        }}
                      >
                        {range[1]?.toDateString()}
                      </div>
                      <button
                        type="button"
                        onClick={() => handleDateClick(range[0])}
                        style={{
                          margin: '5px',
                          backgroundColor: 'red',
                          color: 'white',
                          border: 'none',
                          padding: '4px',
                          borderRadius: '5px',
                          fontSize: '14px'
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles['calendar']}>
          <div className={styles['calendar__input']} onClick={() => setIsActive(!isActive)}>
            {/* Dates: <b>From → To</b> */}

            <div className={styles['calendar__input--date-view']}>
              {selectedDate?.length > 0
                ? `${
                    Array.isArray(selectedDate[0])
                      ? selectedDate[0][0].toDateString()
                      : selectedDate[0].toDateString()
                  }-${
                    Array.isArray(selectedDate[1])
                      ? selectedDate[1][1].toDateString()
                      : selectedDate[1].toDateString()
                  }`
                : 'Select Date'}
            </div>
          </div>
          {isActive ? (
            <div className={styles['calendar__calendar-container']}>
              <div className={styles['calendar__calendar-container__dropdowns-container']}>
                <div className={styles['calendar__calendar-container__dropdowns-container__item']}>
                  <Dropdown
                    options={monthOptions}
                    value={monthValue}
                    onChange={(val: any) => {
                      setMonthValue(val);
                      onYearOrMonthChange(val);
                    }}
                    placeholder="Select an option"
                  />
                </div>
                <div className={styles['calendar__calendar-container__dropdowns-container__item']}>
                  <Dropdown
                    options={yearOptions}
                    value={yearValue}
                    onChange={(val: any) => {
                      setYearValue(val.value);
                      onYearOrMonthChange(null, val.value);
                    }}
                    placeholder="Select an option"
                  />
                </div>
              </div>
              <ReactCalendar
                tileDisabled={(date: any) => {
                  if (data && data.disbaleDate) {
                    return data.disbaleDate.includes(date);
                  }
                  return false;
                }}
                onChange={(val: any) => {
                  onChange(val);
                  eventHandlers.onChange(val as Date[]);
                  setIsActive(!isActive);
                  setSelectedDate(val);
                }}
                value={value}
                selectRange={true}
                next2Label={null}
                prev2Label={null}
                navigationLabel={() => null}
                activeStartDate={activeStartDate}
                onActiveStartDateChange={({ activeStartDate }) => {
                  setYearValue(String(activeStartDate.getFullYear()));
                  const findMonth = monthOptions.find((opt) => {
                    return opt.value === String(activeStartDate.getMonth());
                  });
                  if (findMonth) {
                    setMonthValue(findMonth);
                    onYearOrMonthChange(findMonth, String(activeStartDate.getFullYear()));
                  }
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
