import React, { useState, useEffect } from 'react';
import styles from './add-custom-price-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { Calendar } from '../../../../../shared/components/widgets/calendar/calendar.component';
import {
  InputText,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import { CustomPriceModel } from '../../../../../shared/models/unit-types.model';

export interface AddCustomPriceModalProps {
  data: {
    customPrice: CustomPriceModel[];
    basePrice: number;
  };
  config: {
    showModal: boolean;
  };

  eventHandlers: {
    denyAction: () => void;
    confirmAction: () => void;
    addCustomPrice: (date: any, price: string) => void;
  };
}

/**
 * functional component AddCustomPriceModalProps
 * @param {AddCustomPriceModalProps}
 */
export const AddCustomPriceModal: React.FC<AddCustomPriceModalProps> = ({
  eventHandlers,
  config,
  data
}) => {
  const isMobileView = useWindowResizer();
  const [selectedDates, setSelectedDates] = useState<[Date, Date][]>();
  const [disabledDates, setDisabledDates] = useState<Date[]>();
  const [price, setPrice] = useState<string>('');
  const [showOverLappingError, setShowOverLappingError] = useState<boolean>(false);

  useEffect(() => {
    setPrice('');
    setShowOverLappingError(false);
  }, []);

  useEffect(() => {
    console.log('Custom Prices', data.customPrice);
    const tempDisabledDated =
      data.customPrice && data.customPrice.length > 0
        ? data.customPrice.reduce((acc: Date[], availObj: CustomPriceModel) => {
            const dates = getDaysArray(new Date(availObj.from), new Date(availObj.to));
            return [...acc, ...dates];
          }, [])
        : [];
    setDisabledDates(tempDisabledDated);
  }, [data.customPrice]);

  const HandleAddPrice = (isOverLap: boolean) => {
    if (isOverLap) {
      setShowOverLappingError(true);
    } else {
      setShowOverLappingError(false);
      // tslint:disable-next-line: no-unused-expression
      selectedDates && price && eventHandlers.addCustomPrice(selectedDates, price);

      eventHandlers.denyAction();
    }
  };
  const checkDateOverlap = (date: any) => {
    let isDateOverlap = false;
    data?.customPrice?.map((item) => {
      if (date[0] <= item.to && item.from <= date[1]) {
        isDateOverlap = true;
      } else isDateOverlap = false;
    });
    HandleAddPrice(isDateOverlap);
  };
  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          setSelectedDates([]);
          eventHandlers.denyAction();
        }
      }}
    >
      <div className={styles['confirm-modal']}>
        <div className={styles['confirm-modal__title']}>Add Custom Price </div>
        <div className={styles['confirm-modal__description']}>
          <div className={styles['confirm-modal__description--input']}>
            price:
            <InputText
              data={{
                value: price
              }}
              config={{
                maxRows: 1,
                autoFocus: true,
                type: InputTextType.number
              }}
              eventHandlers={{
                onChangeHandler: (value: string, isValid: boolean) => {
                  setPrice(value);
                }
              }}
            />
          </div>
          {showOverLappingError && <div>can't pick this date it's overlapping</div>}
          <Calendar
            data={{
              calenderView: true,
              disbaleDate: disabledDates,
              basePriceTag: data.basePrice,
              showDateInput: false,
              customPriceTags: data.customPrice,
              multipleRanges: true,
              dateRanges: selectedDates
            }}
            eventHandlers={{
              onChange: (val) => {
                setSelectedDates(val as unknown as [Date, Date][]);
              }
            }}
          />
        </div>
        <div className={styles['confirm-modal--actions']}>
          <Button
            onClick={() => {
              eventHandlers.denyAction();
            }}
            type={ButtonType.primaryInverted}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            No
          </Button>
          <Button
            onClick={() => {
              checkDateOverlap(selectedDates);
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            disabled={!selectedDates || price === ''}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const getDaysArray = (s: any, e: any) => {
  const a: Date[] = [];
  e.setDate(e.getDate() + 1);
  for (const d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
    console.log('a>>', a);
    a.push(new Date(d));
  }
  return a;
};
